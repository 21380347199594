'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export default function HomeTestimonialSection() {
    return (
        <section className=" theme-pt-40 theme-pb-40 theme-pl-30 theme-pr-30">
            <div className="auto-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-30">
                        <div className="site-heading text-left">
                            <h2>DEPOIMENTOS</h2>
                            <p>Opinião de alguns de nossos clientes</p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mr-auto ml-auto">
                        <Swiper
                            id='testimonial'
                            modules={[Navigation]}
                            autoplay={true}
                            spaceBetween={30}
                            loop={true}
                        >
                            <div className='swiper-wrapper'>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Venho na Loja com frequência e sou sempre muito bem atendido pela equipe! Pessoal trabalha rápido e explicam direitinho oque tem de ser feito e porque, oque trás transparência e confiança ao cliente.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Rafael Porto</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Fui atendido essa semana pela equipe da Sport Moto e foram muito prestativos. Serviço de altíssima qualidade. Com certeza ganharam um novo cliente comigo. Recomendo a todos motoqueiros que precisem de algum serviço pra sua moto ir lá que não vão se arrepender. Nota 11.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Ramiro Bastos</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Esta loja de motos é simplesmente incrível! Encontrei aqui não apenas as melhores marcas e modelos, mas também um atendimento excepcional. A equipe é super atenciosa e conhecedora, o que tornou minha experiência de compra muito mais fácil e satisfatória. Recomendo totalmente para todos os entusiastas de motocicletas!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Douglas</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}